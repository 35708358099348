import React from "react"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"

import Layout from "../components/layout"
import PostCard from "../components/post-card"
import SEO from "../components/seo"

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
		) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
						title
            shortDescription
            tagFilter
            author
						featuredImage {
							childImageSharp {
								fluid(maxWidth: 540, maxHeight: 360, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
							}
						}
          }
        }
      }
    }
  }
`
const Pagination = (props) => (
  <div className="pagination">
    <ul>
      {!props.isFirst && (
        <li>
          <Link to={props.prevPage} rel="prev">
            <span className="icon -left"><RiArrowLeftLine /></span> Anterior
          </Link>
        </li>
      )}
      {Array.from({ length: props.numPages }, (_, i) => (
        <li key={`pagination-number${i + 1}`} >
          <Link
            to={`${props.blogSlug}${i === 0 ? '' : i + 1}`}
            className={props.currentPage === i + 1 ? "is-active num" : "num"}
          >
            {i + 1}
          </Link>
        </li>
      ))}
      {!props.isLast && (
        <li>
          <Link to={props.nextPage} rel="next">
            Próximo <span className="icon -right"><RiArrowRightLine /></span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)
class BlogIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allTags: [],
      allPosts: [],
      selectedTags: [],
      filteredPosts: [],
    }
  }

  edges =  this.props.data.allMarkdownRemark.edges

  componentDidMount() {
    this.loadInfos();
  }

  loadInfos = () => {
    let allTags = []
    const allPosts = []

    this.edges.forEach(edge => {
      if (edge.node.frontmatter.date) {
        const tmpTags = edge.node.frontmatter.tagFilter.split(" ");
        allTags = allTags.concat(tmpTags);
        allPosts.push(<PostCard key={edge.node.id} data={edge.node} />)
      }
    });

    this.setState({ allTags: [...new Set(allTags)], allPosts, filteredPosts: allPosts });
  };

  handleTagClick = (value) => {
    let currentTags = []
    
    this.setState((prevState) => {
      currentTags = prevState.selectedTags.includes(value)
      ? prevState.selectedTags.filter(v => v !== value)
      : [...prevState.selectedTags, value]

      return { 
        selectedTags: currentTags,
        filteredPosts: this.filterPosts(currentTags),
      }}
    )
  }

  filterPosts = (currentTags) => {
    const { allPosts } = this.state;
      
    const filteredPosts = allPosts.filter(post => {
      if(currentTags.length === 0) return true

      return post.props.data.frontmatter.tagFilter.split(" ").some(item => currentTags.includes(item))
    })

    this.setState({filteredPosts})
  };

  render() {
    const { currentPage, numPages } = this.props.pageContext
    const blogSlug = '/'
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? blogSlug : blogSlug + (currentPage - 1).toString()
    const nextPage = blogSlug + (currentPage + 1).toString()
   
    let props = {
      isFirst,
      prevPage,
      numPages,
      blogSlug,
      currentPage,
      isLast,
      nextPage
    }

    return (
      <Layout>
        <SEO
          title={"Blog — Page " + currentPage + " of " + numPages}
          description={"blog page " + currentPage + " of " + numPages}
        />
        <div className="container-tags">
          {this.state.allTags.map((tag, index) => (
            <div key={index} 
              onClick={() => this.handleTagClick(tag)} 
              className={`tag ${this.state.selectedTags.includes(tag) ? 'selected' : ''}`}
            >#{tag}</div>
          ))}
        </div>
        <div className="grids col-1 sm-2 lg-3">{this.state.filteredPosts}</div>
        <Pagination {...props} />
      </Layout>
    )
  }
}

export default BlogIndex