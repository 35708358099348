import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const PostCard = ({ data }) => (
  <article className="post-card-background">
    <Link to={data.frontmatter.slug}>
      <div className="post-card">
        {data.frontmatter.featuredImage ?
          (
            <Img
              fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={data.frontmatter.title + ' - Featured image'}
              className="featured-image"
            /> 
          ) : ""
        }
        <div className="post-content">
          <div className="title-container"><h2 className="title">{data.frontmatter.title}</h2></div>
          <p className="tag-card"><b>{data.frontmatter.tagFilter.split(" ").map(t => `#${t}   `)}</b></p>
          <p className="short-desc">{data.frontmatter.shortDescription}</p>
          <p className="meta"><time>{data.frontmatter.author} - {data.frontmatter.date}</time></p>
        </div>
      </div>
    </Link>
  </article>
)

export default PostCard